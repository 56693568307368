import React, { Suspense } from 'react';
import './index.scss';
import { NavBar, Footer } from "../../components";


export const LoadingWithSuspense = (Component, props) => {

    const SuspenseComponent = <Suspense fallback={
        <div className="text-center abilis-loading">Loading...</div>
    }>
        <Component {...props} match={props.match} />
    </Suspense>;

    return (
        <>
            {/*<NavBar />*/}
            {SuspenseComponent}
            {/*<Footer />*/}
        </>
    );
};