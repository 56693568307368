import { navBarConstants } from '../_constants';


export const navBarActions = {
    setBackground,
    setLogo
};

function setBackground(background) {
    return dispatch => {
        dispatch((data => {
            return { type: navBarConstants.SET_BACKGROUND, data }
        })({background}));
    };
}

function setLogo(logo) {
    return dispatch => {
        dispatch((data => {
            return { type: navBarConstants.SET_LOGO, data }
        })({logo}));
    };
}