import React from 'react';
import './index.scss';
import { history } from "../../_library";
import { Text } from "../Text";


export const Footer = () => {

    return (
        <footer className="page-footer px-default pt-24">
            <div className="container-fluid text-md-left">
                <div className="row">
                    <div className="col-md-6 mt-md-0 mt-3">
                        <div className="antek-logo"></div>
                    </div>
                    <div className="col-md-3 mb-md-0 mb-3">
                        <ul className="list-unstyled">
                            <li>
                                <a href="mailto:info@antek.it">
                                    <Text footer>info@antek.it</Text>
                                </a>
                            </li>
                            <li>
                                <a href="tel:+39 0376 397398">
                                    <Text footer>+39 0376 397398</Text>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="col-md-3 mb-md-0 mb-3">
                        <ul className="list-unstyled">
                            <li>
                                <a onClick={() => history.push('/')}>
                                    <Text footer>Home</Text>
                                </a>
                            </li>
                            <li>
                                <a onClick={() => history.push('/catalog')}>
                                    <Text footer label="abilis.navbar.products" />
                                </a>
                            </li>
                            <li>
                                <a onClick={() => history.push('/legal/#impressum')}>
                                    <Text footer>Impressum</Text>
                                </a>
                            </li>
                            <li>
                                <a onClick={() => history.push('/legal/#privacy')}>
                                    <Text footer>Privacy Policy</Text>
                                </a>
                            </li>
                            <li>
                                <a onClick={() => history.push('/legal/#terms')}>
                                    <Text footer>Terms of Use</Text>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="footer-copyright text-center py-3">© Anteklab srl V.Manzoni 49, 46047 Porto Mant. MN Cap.Soc.€10.000 P.Iva 01910240207</div>
        </footer>
    );
};