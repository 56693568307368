import React, { useState } from 'react';
import './index.scss';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Dropdown } from '../Dropdown';
import { Link } from '../Link';
import { i18nActions } from "../../_actions";
import { history } from "../../_library";
import arrowDown from "../../assets/img/Header/arrow-down.svg";


const NavBar = props => {
    const [currentPath, setCurrentPath] = useState(window.location.pathname);

    return (
        <header>
            <nav className={props.navBar.background === 'blue' ? "navbar-blue-background navbar navbar-expand-lg px-default position-absolute w-100" : "navbar navbar-expand-lg px-default position-absolute w-100"}>
                <div className="container-fluid">
                    <a className="navbar-brand" onClick={() => history.push('/')}>
                        <div className={props.navBar.logo === "antek" ? "antek-logo" : props.navBar.logo === "abilis-white" ? "abilis-logo-white" : "abilis-logo"} />
                    </a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                            aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon" />
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <a className={props.navBar.background === 'blue' || props.navBar.background === 'blue-transparent' ? 'nav-link nav-link-white' : 'nav-link' + (currentPath === '/'? ' active' : '')}
                                   aria-current="page" onClick={() => history.push('/')}>
                                    <FormattedMessage id="abilis.navbar.products.home" />
                                </a>
                            </li>
                            <li className="nav-item dropdown">
                                <Dropdown label="abilis.navbar.products"
                                          className={props.navBar.background === 'blue' || props.navBar.background === 'blue-transparent' ? 'nav-link nav-link-white' : 'nav-link dropdown-toggle' + (currentPath === 'router' || currentPath === 'softpbx' || currentPath === 'phone' || currentPath === 'automation' || currentPath === 'vmx' || currentPath === 'voip' ? ' active' : '')}
                                          DropdownText items={[
                                    <Link className={currentPath === 'router' ? ' active' : ''} to="/products/router" label='abilis.navbar.products.router' />,
                                    <Link className={currentPath === 'router' ? ' active' : ''} to="/products/softpbx" label='abilis.navbar.products.softpbx' />,
                                    <Link className={currentPath === 'phone' ? ' active' : ''} to="/products/phone" label='abilis.navbar.products.phone' />,
                                    <Link className={currentPath === 'automation' ? ' active' : ''} to="/products/automation" label='abilis.navbar.products.automation' />,
                                    <Link className={currentPath === 'vmx' ? ' active' : ''} to="/products/vmx" label='abilis.navbar.products.vmx' />,
                                    <Link className={currentPath === 'voip' ? ' active' : ''} to="/products/voip" label='abilis.navbar.products.voip' />
                                ]} />

                            </li>
                            <li className="nav-item">
                                <a className={(props.navBar.background === 'blue' || props.navBar.background === 'blue-transparent' ? 'nav-link nav-link-white' : 'nav-link') + (currentPath === 'documentation' ? ' active' : '')}
                                   href="https://www.abilis.net/tutorial/">
                                    <FormattedMessage id="abilis.navbar.documentation" />
                                </a>
                            </li>
                            {/*<li className="nav-item">*/}
                            {/*    <a className={(props.navBar.background === 'blue' || props.navBar.background === 'blue-transparent' ? 'nav-link nav-link-white' : 'nav-link') + (currentPath === 'documentation' ? ' active' : '')}*/}
                            {/*       onClick={() => history.push('/documentation')}>*/}
                            {/*        <FormattedMessage id="abilis.navbar.documentation" />*/}
                            {/*    </a>*/}
                            {/*</li>*/}
                            {/*<li className="nav-item">*/}
                            {/*    <a className={(state.theme.linkColor === 'lightGrey' ? 'nav-link nav-link-white' : 'nav-link') + (currentPath === 'catalog' ? ' active' : '')} href="/catalog">Catalog</a>*/}
                            {/*</li>*/}
                            <li className="nav-item abilis-languages">
                                <a onClick={() => props.changeLanguage('en')} className={(props.navBar.background === 'blue' || props.navBar.background === 'blue-transparent' ? 'nav-link nav-link-white' : 'nav-link') + (props.i18n.lang === 'en' ? ' active' : '')}>
                                    EN
                                </a>
                            </li>
                            <li className="nav-item">
                                <a onClick={() => props.changeLanguage('it')} className={(props.navBar.background === 'blue' || props.navBar.background === 'blue-transparent' ? 'nav-link nav-link-white' : 'nav-link') + (props.i18n.lang === 'it' ? ' active' : '')}>
                                    IT
                                </a>
                            </li>
                            {/*<li className="nav-item">*/}
                            {/*    <a className={state.theme.linkColor === 'lightGrey' ? 'nav-link nav-link-white' : 'nav-link'} href="/">RU</a>*/}
                            {/*</li>*/}
                        </ul>
                    </div>
                </div>
            </nav>
        </header>
    );
};

function mapStateToProps(state) {
    const { navBar, i18n } = state;
    return {
        navBar,
        i18n
    };
}

function mapDispatchToProps(dispatch) {
    return({
        changeLanguage: lang => {
            dispatch(i18nActions.changeLanguage(lang))
        }
    })
}

const connectedNavBar = connect(mapStateToProps, mapDispatchToProps)(NavBar);
export { connectedNavBar as NavBar };