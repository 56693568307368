import React from 'react';
import './index.scss';
import { FormattedMessage } from 'react-intl';


export const Text = props => {

    let Elem = 'span';

    if (props.element) {
        Elem = props.element;
    }

    if (props.left || props.right || props.justify || props.center) {
        Elem = 'div';
    }

    const classes = 'abilis-text ' + (props.small ? 'abilis-text-small ' : '') +
        (props.title ? 'abilis-text-title ' : '') +
        (props.subtitle ? 'abilis-text-subtitle ' : '') +
        (props.left ? 'abilis-text-left ' : '') +
        (props.right ? 'abilis-text-right ' : '') +
        (props.center ? 'abilis-text-center ' : '') +
        (props.justify ? 'abilis-text-justify ' : '') +
        (props.white ? 'abilis-white-text ' : '') +
        (props.blue ? 'abilis-blue-text ' : '') +
        (props.grey ? 'abilis-grey-text ' : '') +
        (props.dark ? 'abilis-dark-text ' : '') +
        (props.team ? 'abilis-team-text ' : '') +
        (props.thick ? 'abilis-thick-text ' : '') +
        (props.small ? 'abilis-small-text ' : '') +
        (props.footer ? 'abilis-footer-text ' : '') +
        (props.bold ? 'abilis-bold-text ' : '') +
        (props.whiteTickBig ? 'abilis-white-thick-big-text ' : '') +
        (props.moreSpace ? 'abilis-more-space-text ' : '') +
        (props.h1 ? 'abilis-text-h1 ' : '') +
        (props.h2 ? 'abilis-text-h2 ' : '') +
        (props.h3 ? 'abilis-text-h3 ' : '') +
        (props.h4 ? 'abilis-text-h4 ' : '') +
        (props.h5 ? 'abilis-text-h5 ' : '') +
        (props.h6 ? 'abilis-text-h6 ' : '') +
        (props.className ? props.className : '');

    if (props.label) {
        return <FormattedMessage id={ props.label } >
            {msg => (
                <Elem {...(props.onClick ? {onClick: props.onClick} : undefined)} className={classes}>{msg}</Elem>
            )}
        </FormattedMessage>;
    }

    return <Elem {...(props.onClick ? {onClick: props.onClick} : undefined)} className={classes}>{props.children}</Elem>;
};