import { navBarConstants } from '../_constants';

const initialState = {
    logo: 'antek',
    background: 'grey'
};

export function navBar(state = Object.assign({}, initialState), action) {
    switch (action.type) {
        case navBarConstants.SET_BACKGROUND:

            if (action.data.background === null || action.data.background === undefined) {
                state.background = 'grey';
            } else {
                state.background = action.data.background;
            }

            return Object.assign({}, state);
        case navBarConstants.SET_LOGO:

            if (action.data.logo === null || action.data.logo === undefined) {
                state.background = 'antek';
            } else {
                state.logo = action.data.logo;
            }

            return Object.assign({}, state);
        default:
            return state;
    }
}