import React from "react";
import ReactDOM from "react-dom";
import { ApolloProvider} from "@apollo/client"
import App from "./containers/App";
import client from "./utils/apolloClient";
import { Provider } from 'react-redux';
import './assets/scss/abilis_style.scss';
import {store} from "./_library";
import { IntlProvider } from './_library/connectedIntlProvider';


ReactDOM.render(
    <Provider store={store}>
        <ApolloProvider client={client}>
            <IntlProvider>
                <App />
            </IntlProvider>
        </ApolloProvider>
    </Provider>,
    document.getElementById("root")
);