import React, { useState } from 'react';
import './index.scss';
// import { Button, Icon, Text } from '../';
import { Dropdown as RDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { history } from '../../_library';
import ArrowDown from "../../assets/img/Header/arrow-down.svg";


export const Dropdown = props => {

    const [dropdownOpen, setDropdownOpen] = useState(false);

    let toggle = () => setDropdownOpen(!dropdownOpen);
    let isOpen = dropdownOpen;

    if (props.toggle !== undefined) {
        toggle = props.toggle;
        isOpen = props.open;
    }

    //-18 -17 arrowPosition(half of button length)

    return <>
            <RDropdown className={'abilis-dropdown ' + (props.className ? props.className : '')} isOpen={isOpen} toggle={toggle}>
                <DropdownToggle tag="span">
                    {props.label &&
                        <FormattedMessage id={props.label} />
                    }
                    {props.text &&
                        props.text
                    }
                </DropdownToggle>
                <DropdownMenu right className={'abilis-dropdown-menu abilis-text-center ' + (props.big ? 'abilis-dropdown-menu-large' : '')} data-arrow-position={props.arrowPosition} >
                    <img className="abilis-arrow-down" alt="arrow-down" src={ArrowDown}/>
                    {!props.link && props.items && props.items.map((item, idx) => (
                            <DropdownItem key={idx} tag="div" className="dropdown-menu-item">{item}</DropdownItem>
                        ))
                    }
                    {props.link && props.items && props.items.map((item, idx) => (
                            <DropdownItem onClick={item.onClick !== undefined ? item.onClick : () => {history.push(item.to)}} key={idx} tag="div" className="abilis-dropdown-menu-item-link">
                                <FormattedMessage id={item.label}/></DropdownItem>
                            )
                        )
                    }
                    {props.children && props.children}
                </DropdownMenu>
            </RDropdown>
        </>;
};
