import { combineReducers } from 'redux';
import { i18n } from './i18n.reducer';
import { navBar } from './navBar.reducer';


const rootReducer = combineReducers({
    i18n,
    navBar
});

export default rootReducer;