import React, { Component } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { history } from '../../_library';
import { LoadingWithSuspense } from '../../components/LoadingWithSuspense';


const Main = React.lazy(() => import(/* webpackChunkName: "abilis_main" */ '../../screens/Main/Main').then(module => ({default: module.Main})));
const Advantages = React.lazy(() => import(/* webpackChunkName: "advantages" */ '../../screens/Advantages/Advantages').then(module => ({default: module.Advantages})));
const Client = React.lazy(() => import(/* webpackChunkName: "client" */ '../../screens/Client/Client').then(module => ({default: module.Client})));
const Legal = React.lazy(() => import(/* webpackChunkName: "legal" */ '../../screens/Legal/Legal').then(module => ({default: module.Legal})));
const VPNRouter = React.lazy(() => import(/* webpackChunkName: "vpn_router" */ '../../screens/Products/VPNRouter/VPNRouter').then(module => ({default: module.VPNRouter})));
const SoftPBX = React.lazy(() => import(/* webpackChunkName: "soft_pbx" */ '../../screens/Products/SoftPBX/SoftPBX').then(module => ({default: module.SoftPBX})));
const AbilisPhone = React.lazy(() => import(/* webpackChunkName: "abilis_phone" */ '../../screens/Products/AbilisPhone/AbilisPhone').then(module => ({default: module.AbilisPhone})));
const BuildingAutomation = React.lazy(() => import(/* webpackChunkName: "automation" */ '../../screens/Products/BuildingAutomation/BuildingAutomation').then(module => ({default: module.BuildingAutomation})));
const VMX = React.lazy(() => import(/* webpackChunkName: "vmx" */ '../../screens/Products/VMX/VMX').then(module => ({default: module.VMX})));
const VoIP = React.lazy(() => import(/* webpackChunkName: "voip" */ '../../screens/Products/VoIP/VoIP').then(module => ({default: module.VoIP})));
const Catalog = React.lazy(() => import(/* webpackChunkName: "catalog" */ '../../screens/Catalog/Catalog').then(module => ({default: module.Catalog})));
const Documentation = React.lazy(() => import(/* webpackChunkName: "documentation" */ '../../screens/Documentation/Documentation').then(module => ({default: module.Documentation})));

class App extends Component {

    render() {
        return (
            <Router history={history}>
                <Switch>
                    <Route exact path="/" render={(props) => LoadingWithSuspense(Main, props)}/>
                    <Route exact path="/advantages" render={(props) => LoadingWithSuspense(Advantages, props)}/>
                    <Route exact path="/client" render={(props) => LoadingWithSuspense(Client, props)}/>
                    <Route exact path="/legal" render={(props) => LoadingWithSuspense(Legal, props)}/>
                    <Route exact path="/products/router" render={(props) => LoadingWithSuspense(VPNRouter, props)}/>
                    <Route exact path="/products/softpbx" render={(props) => LoadingWithSuspense(SoftPBX, props)}/>
                    <Route exact path="/products/phone" render={(props) => LoadingWithSuspense(AbilisPhone, props)}/>
                    <Route exact path="/products/automation" render={(props) => LoadingWithSuspense(BuildingAutomation, props)}/>
                    <Route exact path="/products/vmx" render={(props) => LoadingWithSuspense(VMX, props)}/>
                    <Route exact path="/products/voip" render={(props) => LoadingWithSuspense(VoIP, props)}/>
                    <Route exact path="/catalog" render={(props) => LoadingWithSuspense(Catalog, props)}/>
                    <Route exact path="/documentation" render={(props) => LoadingWithSuspense(Documentation, props)}/>
            //     {/*<Route exact path="/404" render={(props) => LoadingWithSuspense(PageNotFound, props)} />*/}
            //     {/*<Route render={(props) => LoadingWithSuspense(PageNotFound, props)} />*/}
               </Switch>
            </Router>
        );
    }
}

export default App;
